import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const ForgotPasswordSlide = createSlice({
    name: "forgotPassword",
    initialState: {
        sidebarShow: true,
        commonData: null,
        loggedIn: false,
        urlParam: null,
        loginFailedDetail: null,
        loginSuccess: false,
        logoutSuccess: false,
        logoutFail: false,
    },
    reducers: {
        setSideBarShow: (state, action) => {
            state.sidebarShow = action.payload;
        },
    },
});

export const { 
    setSideBarShow,
} = ForgotPasswordSlide.actions;

export default ForgotPasswordSlide.reducer;