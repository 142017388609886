import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const CustomerCartSlide = createSlice({
    name: "customerCart",
    initialState: {
        addToCartSuccess: false,
        addToCartFail: false,
        addToCartErrorDetail: null,
        cartDetail: null,
    },
    reducers: {
        doAddToCartSuccess: (state, action) => {
            state.addToCartSuccess = true;
            state.addToCartFail = false;
            state.addToCartErrorDetail = null;
        },
        doAddToCartFail: (state, action) => {
            state.addToCartSuccess = false;
            state.addToCartFail = true;
            state.addToCartErrorDetail = action.payload.data;
        },
        resetAddToCartState: (state, action) => {
            state.addToCartSuccess = false;
            state.addToCartFail = false;
            state.addToCartErrorDetail = null;
        },
        doGetCartDetailSuccess: (state, action) => {
            state.cartDetail = action.payload.data;
        },
        doGetCartDetailFail: (state, action) => {
            state.cartDetail = null;
        },
    },
});

export const doAddToCart = (param) => async (dispatch) => {
    try {
        const url = `/api/customer/cart/add`;
        const response = await axios.post(url, param);
        dispatch(doAddToCartSuccess(response.data));
    } catch (error) {
        dispatch(doAddToCartFail(error.response));
    }
};

export const doResetAddToCartState = () => async (dispatch) => {
    dispatch(resetAddToCartState())
};

export const doGetCartDetail = () => async (dispatch) => {
    try {
        const url = `/api/customer/cart`;
        const response = await axios.get(url);
        dispatch(doGetCartDetailSuccess(response.data));
    } catch (error) {
        dispatch(doGetCartDetailFail(error.response));
    }
};

export const { 
    doAddToCartSuccess,
    doAddToCartFail,
    resetAddToCartState,
    doGetCartDetailSuccess,
    doGetCartDetailFail,
} = CustomerCartSlide.actions;

export const getAddToCartSuccess = (state) => state.customerCart?.addToCartSuccess;
export const getAddToCartFail = (state) => state.customerCart?.addToCartFail;
export const getAddToCartErrorDetail = (state) => state.customerCart?.addToCartErrorDetail;

export const getCartDetail = (state) => state.customerCart?.cartDetail;

export default CustomerCartSlide.reducer;
