import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const coreSlide = createSlice({
    name: "core",
    initialState: {
        sidebarShow: true,
        commonData: null,
        urlParam: null,

        registerSuccess: false,
        registerFail: false,
        registerErrorMessage: null,
        registerResponseData: null,

        loggedIn: false,
        loginFailedDetail: null,
        loginSuccess: false,
        logoutSuccess: false,
        logoutFail: false,
    },
    reducers: {
        setSideBarShow: (state, action) => {
            state.sidebarShow = action.payload;
        },
        setUrlParam: (state, action) => {
            state.urlParam = action.payload;
        },
        setCommonData: (state, action) => {
            state.commonData = action.payload.meta;
        },
        doRegisterSuccess: (state, action) => {
            state.registerSuccess = true;
            state.registerFail = false;
            state.registerErrorMessage = null;
            state.registerResponseData = action.payload.data;
        },
        doRegisterFail: (state, action) => {
            state.registerSuccess = false;
            state.registerFail = true;
            state.registerErrorMessage = action.payload.data;
        },
        resetRegisterState: (state, action) => {
            state.registerSuccess = action.payload;
            state.registerFail = action.payload;
            state.registerResponseData = null;
            state.registerErrorMessage = null;
        },
        
        doLoginSuccess: (state, action) => {
            state.user = action.payload.data;
            state.loginSuccess = true;
            state.logoutSuccess = false;
            state.loginFailedDetail = null;
        },
        doLoginFail: (state, action) => {
            state.loggedIn = null;
            state.loginSuccess = false;
            state.loginFailedDetail = action.payload.data;
        },
        resetLoginFail: (state, action) => {
            state.loginFailedDetail = null;
        },
        doLoggedInSuccess: (state, action) => {
            state.loggedIn = action.payload;
        },
        doLoggedInFail: (state, action) => {
            state.loggedIn = null;
        },
        doLogoutSuccess: (state, action) => {
            state.loggedIn = null;
            state.loginSuccess = false;
            state.logoutSuccess = true;
            state.logoutFail = false;
        },
        doLogoutFail: (state, action) => {
            state.logoutSuccess = false;
            state.logoutFail = true;
        },
        resetLogoutState: (state, action) => {
            state.logoutSuccess = action.payload;
            state.logoutFail = action.payload;
        },
    },
});

export const doLogin = (param) => async (dispatch) => {
    try {
        const url = `/api/customer/auth/login`;
        const response = await axios.post(url, param);
        dispatch(doLoginSuccess(response.data));
    } catch (error) {
        dispatch(doLoginFail(error.response));
    }
};

export const doGetCommonData = () => async (dispatch) => {
    try {
        const response = await axios.get("/api/customer/commons/meta");
        dispatch(setCommonData({ meta: response.data }));
    } catch (error) {
        dispatch(setCommonData({ meta: null }));
    }
};

export const doDepositBeforeRegister = (param) => async (dispatch) => {
    try {
        let data = param;
    
        const url = `/api/admin/auth/register/deposit`;
        const response = await axios.post(url, data);

        dispatch(doDepositBeforeRegisterSuccess(response.data));
    } catch (error) {
        dispatch(doDepositBeforeRegisterFail(error.response));
    }
};

export const doRegister = (param) => async (dispatch) => {
    try {
        let data = param;

        const url = `/api/customer/auth/register`;
        const response = await axios.post(url, data);

        dispatch(doRegisterSuccess(response.data));
    } catch (error) {
        dispatch(doRegisterFail(error.response));
    }
};

export const doResetRegisterState = () => async (dispatch) => {
    dispatch(resetRegisterState(false));
};

export const doGetLoggedInUser = () => async (dispatch) => {
	try {
        const url = `/api/customer/auth/me`;
        const response = await axios.get(url);
        dispatch(doLoggedInSuccess(response.data));
    } catch (error) {
        dispatch(doLoggedInFail(error.response));
    }
};

export const doLogout = () => async (dispatch) => {
    try {
        const url = `/api/customer/auth/logout`;
        const response = await axios.get(url);
        dispatch(doLogoutSuccess(response.data));
    } catch (error) {
        dispatch(doLogoutFail(error.response));
    }
};

export const doResetLogoutState = () => async (dispatch) => {
    dispatch(resetLogoutState(false));
};

export const doSetUrlParam = (param) => async (dispatch) => {
   dispatch(setUrlParam(param));
};

export const updateSidebarShow = (param) => async (dispatch) => {
    dispatch(setSideBarShow(param));
 };

export const { 
    setSideBarShow,
    setCommonData, 
    doDepositBeforeRegisterSuccess,
    doDepositBeforeRegisterFail,
    doCheckTransactionSuccess,
    doCheckTransactionFail,
    doRegisterSuccess,
    doRegisterFail,
    doLoginSuccess,
    resetRegisterState,
    doGetReferralUserDetailSuccess,
    doGetReferralUserDetailFail,
    setUrlParam,
    doLoginFail,
    doLoggedInSuccess, 
    doLoggedInFail, 
    resetLoginFail,
    doLogoutSuccess,
    doLogoutFail,
    resetLogoutState,
} = coreSlide.actions;

export const getSideBarShow = (state) => state.core?.sidebarShow;
export const getCommonData = (state) => state.core?.commonData;

export const getRegisterSuccess = (state) => state.core?.registerSuccess;
export const getRegisterFail = (state) => state.core?.registerFail;
export const getRegisterErrorMessage = (state) => state.core?.registerErrorMessage;
export const getRegisterResponseData = (state) => state.core?.registerResponseData;

export const getLoginFailedDetail = (state) => state.core?.loginFailedDetail;
export const getLoginSuccess = (state) => state.core?.loginSuccess;
export const getLoggedIn = (state) => state.core?.loggedIn;
export const getLogoutSuccess = (state) => state.core?.logoutSuccess;
export const getUrlParam = (state) => state.core?.urlParam;

export default coreSlide.reducer;
