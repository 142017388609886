import { combineReducers } from "redux"
import storage from "redux-persist/lib/storage"

import core from "./reducer_core"
import forgotPassword from "./reducer_forgot_password"
import userProfile from "./reducer_user_profile"
import customerCart from "./reducer_customer_cart"
import customerRaffle from "./reducer_customer_raffle"
import customerPayment from "./reducer_customer_payment"
import customerOrder from "./reducer_customer_order"
import landing from "./reducer_landing"

const appReducer = combineReducers({
    core,
    forgotPassword,
    userProfile,
    customerCart,
    customerRaffle,
    customerPayment,
    customerOrder,
    landing,
})

const rootReducer = (state, action) => {
    if (action.type === "USER_LOGOUT") {
        Object.keys(state).forEach((key) => {
            storage.removeItem(`persist:${key}`)
        })
        state = undefined
    }

    return appReducer(state, action)
}

export default rootReducer
