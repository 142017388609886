import "react-app-polyfill/stable"
import "core-js"
import React from "react"
import ReactDOM from "react-dom"
import { createTheme, ThemeProvider, responsiveFontSizes } from "@mui/material/styles"
import { GoogleOAuthProvider } from '@react-oauth/google';

import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { Provider } from "react-redux"
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react"
import store from "./store"

const persistor = persistStore(store);

let theme = createTheme({
    typography: {
        fontFamily: ["Poppins", "sans-serif"].join(","),
    },
    palette: {
        primary: {
            main: "#280B59",
        },
        secondary: {
            main: "#ffcc80",
        },
        white: {
            main: "#ffffff",
            overrides: {
                MuiButton: {
                    label: {
                        color: "#1C60DD",
                    },
                },
            },
        },
    },
})

theme = responsiveFontSizes(theme);

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider theme={theme}>
                <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                    <App />
                </GoogleOAuthProvider>
            </ThemeProvider>
        </PersistGate>
    </Provider>,
    document.getElementById("root"),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
