import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const CustomerPaymentSlide = createSlice({
    name: "customerPayment",
    initialState: {
        paymentUrlParam: null,
        paymentSuccess: false,
        paymentFail: false,
        paymentErrorDetail: null,
        clientSecret: null,
    },
    reducers: {
        setPaymentUrlParam: (state, action) => {
            state.paymentUrlParam = action.payload
        },
        doPaymentSuccess: (state, action) => {
            state.paymentSuccess = true;
            state.paymentFail = false;
            state.paymentErrorDetail = null;
        },
        doPaymentFail: (state, action) => {
            state.paymentSuccess = false;
            state.paymentFail = true;
            state.paymentErrorDetail = action.payload.data;
        },
        resetPaymentState: (state, action) => {
            state.paymentSuccess = false;
            state.paymentFail = false;
            state.paymentErrorDetail = null;
        },
        doGetPaymentClientSecretSuccess: (state, action) => {
            state.clientSecret = action.payload.clientSecret;
        },
        doGetPaymentClientSecretFail: (state, action) => {
            state.clientSecret = null;
        },
        resetPaymentClientSecretState: (state, action) => {
            state.clientSecret = null;
        },
    },
});

export const doSetPaymentUrlParam = (param) => async (dispatch) => {
    dispatch(setPaymentUrlParam(param));
};

export const doPayment = (param) => async (dispatch) => {
    try {
        const url = `/api/customer/payment`;
        const response = await axios.post(url, param);
        dispatch(doPaymentSuccess(response.data));
    } catch (error) {
        dispatch(doPaymentFail(error.response));
    }
};

export const doResetPaymentState = () => async (dispatch) => {
    dispatch(resetPaymentState())
};

export const doGetPaymentClientSecret = (param) => async (dispatch) => {
    try {
        const url = `/api/customer/payment/client-secret`;
        const response = await axios.post(url, param);
        dispatch(doGetPaymentClientSecretSuccess(response.data));
    } catch (error) {
        dispatch(doGetPaymentClientSecretFail(error.response));
    }
};

export const doResetPaymentClientSecretState = () => async (dispatch) => {
    dispatch(resetPaymentClientSecretState())
};

export const { 
    setPaymentUrlParam,
    doPaymentSuccess,
    doPaymentFail,
    resetPaymentState,
    doGetPaymentClientSecretSuccess,
    doGetPaymentClientSecretFail,
    resetPaymentClientSecretState,
} = CustomerPaymentSlide.actions;

export const getPaymentUrlParam = (state) => state.customerPayment?.paymentUrlParam;
export const getPaymentSuccess = (state) => state.customerPayment?.paymentSuccess;
export const getPaymentFail = (state) => state.customerPayment?.paymentFail;
export const getPaymentErrorDetail = (state) => state.customerPayment?.paymentErrorDetail;

export const getPaymentClientSecret = (state) => state.customerPayment?.clientSecret;

export default CustomerPaymentSlide.reducer;
