import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const CustomerOrderSlide = createSlice({
    name: "customerOrder",
    initialState: {
        createOrderSuccess: false,
        createOrderFail: false,
        createOrderErrorDetail: null,
    },
    reducers: {
        doCreateOrderSuccess: (state, action) => {
            state.createOrderSuccess = true;
            state.createOrderFail = false;
            state.createOrderErrorDetail = null;
        },
        doCreateOrderFail: (state, action) => {
            state.createOrderSuccess = false;
            state.createOrderFail = true;
            state.createOrderErrorDetail = action.payload.data;
        },
        resetCreateOrderState: (state, action) => {
            state.createOrderSuccess = false;
            state.createOrderFail = false;
            state.createOrderErrorDetail = null;
        },
    },
});

export const doCreateOrder = (param) => async (dispatch) => {
    try {
        const url = `/api/customer/order`;
        const response = await axios.post(url, param);
        dispatch(doCreateOrderSuccess(response.data));
    } catch (error) {
        dispatch(doCreateOrderFail(error.response));
    }
};

export const doResetCreateOrderState = () => async (dispatch) => {
    dispatch(resetCreateOrderState())
};

export const { 
    doCreateOrderSuccess,
    doCreateOrderFail,
    resetCreateOrderState,
} = CustomerOrderSlide.actions;

export const getCreateOrderSuccess = (state) => state.customerOrder?.createOrderSuccess;
export const getCreateOrderFail = (state) => state.customerOrder?.createOrderFail;
export const getCreateOrderErrorDetail = (state) => state.customerOrder?.createOrderErrorDetail;

export default CustomerOrderSlide.reducer;
