import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const LandingSlide = createSlice({
    name: "landing",
    initialState: {
        products: [],
    },
    reducers: {
        doGetProductsSuccess: (state, action) => {
            state.products = action.payload.data;
        },
        doGetProductsFail: (state, action) => {
            state.products = [];
        },
    },
});

export const doGetProducts = (param) => async (dispatch) => {
    try {
        const url = `/api/customer/landing/product`;
        const response = await axios.get(url);
        dispatch(doGetProductsSuccess(response.data));
    } catch (error) {
        dispatch(doGetProductsFail(error.response));
    }
};

export const { 
    doGetProductsSuccess,
    doGetProductsFail,
    resetAddToCartState,
} = LandingSlide.actions;

export const getProducts = (state) => state.landing?.products;

export default LandingSlide.reducer;
