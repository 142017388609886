import React, { Suspense, useEffect, useState } from "react"
import { HashRouter, Route, Routes, useLocation } from "react-router-dom"
import CircularProgress from "@mui/material/CircularProgress"
import Typography from "@mui/material/Typography"

import "./scss/style.scss"
import routes from "src/dashboardRoutes"
import { useMediaQuery } from "react-responsive"

const loading = (
    <div className="h-100vh d-flex">
        <div className="d-flex flex-column m-auto">
            <CircularProgress />
            <Typography variant="caption" className="mt-3" color="primary">Loading...</Typography>
        </div>
    </div>
)

function ScrollToTop() {
    const { pathname } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
}

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"))

// Pages
// const Login = React.lazy(() => import("./pages/login/Login"))
// const SignUp = React.lazy(() => import("./pages/sign_up/SignUp"))
const Page404 = React.lazy(() => import("./pages/page404/Page404"))
const Page500 = React.lazy(() => import("./pages/page500/Page500"))
const Landing = React.lazy(() => import("./pages/landing"))
const AboutUs = React.lazy(() => import("./pages/about_us/AboutUs"))
const ContactUs = React.lazy(() => import("./pages/contact_us/ContactUs"))
const PrivacyPolicy = React.lazy(() => import("./pages/privacy_policy/PrivacyPolicy"))
const TermAndCondition = React.lazy(() => import("./pages/term_and_condition/TermAndCondition"))
const ForgotPassword = React.lazy(() => import("./pages/forgot-password/ForgotPassword"))
const ForgotPasswordSent = React.lazy(() =>
    import("./pages/forgot_password_sent/ForgotPasswordSent"),
)
const ResetPassword = React.lazy(() => import("./pages/reset_password/ResetPassword"))
const ResetPasswordSuccess = React.lazy(() =>
    import("./pages/reset_password_success/ResetPasswordSuccess"),
)
const PaymentReturnFail = React.lazy(() => import("./pages/payment_return_fail/PaymentReturnFail"))
const VerifyEmail = React.lazy(() => import("./pages/verify_email/VerifyEmail"))
const Cart = React.lazy(() => import("./pages/cart/Cart"))
const Payment = React.lazy(() => import("./pages/payment/index"))

const App = () => {
    const isDesktop = useMediaQuery({ minWidth: 992 })
    const [bodyStyles, setBodyStyles] = useState({});

    useEffect(() => {
        function getScrollbarWidth() {
            var scrollDiv = document.createElement("div");
            scrollDiv.className = "scrollbar-measure";
            document.body.appendChild(scrollDiv);
    
            // Get the scrollbar width
            var scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;

            // Delete the DIV 
            document.body.removeChild(scrollDiv);
            return scrollbarWidth;
        }
        
        var actualWidth = 1440 - getScrollbarWidth();

        if (isDesktop) {
            setBodyStyles({
                "font-family": "Poppins, sans-serif",
                width: `${actualWidth}px`,
                margin: "0 auto",
            });
        } else {
            setBodyStyles({
                "font-family": "Poppins, sans-serif",
            });
        }
    }, [isDesktop]);

    return (
        <HashRouter>
            <ScrollToTop />
            <Suspense fallback={loading}>
                <style>
                    {`
                        body {
                            ${Object.entries(bodyStyles).map(([key, value]) => `${key}: ${value};`).join('\n')}
                        }
                    `}
                </style>
                <Routes>
                    {/* <Route exact path="/login" name="Login Page" element={<Login />} /> */}
                    {/* <Route exact path="/sign-up" name="Sign Up Page" element={<SignUp />} /> */}
                    <Route exact path="/404" name="Page 404" element={<Page404 />} />
                    <Route exact path="/500" name="Page 500" element={<Page500 />} />
                    {/* <Route exact path="/about-us" name="About Us" element={<AboutUs />} /> */}
                    {/* <Route exact path="/contact-us" name="Contact Us" element={<ContactUs />} /> */}
                    <Route exact path="/cart" name="Cart" element={<Cart />} />
                    <Route exact path="/payment" name="Payment" element={<Payment />} />
                    {/* <Route
                        exact
                        path="/privacy-policy"
                        name="Privacy Policy"
                        element={<PrivacyPolicy />}
                    /> */}
                    {/* <Route
                        exact
                        path="/term-and-condition"
                        name="Term and Condition"
                        element={<TermAndCondition />}
                    /> */}
                    {/* <Route
                        exact
                        path="/forgot-password"
                        name="Forgot Password"
                        element={<ForgotPassword />}
                    /> */}
                    {/* <Route
                        exact
                        path="/forgot-password-sent"
                        name="Forgot Password Sent"
                        element={<ForgotPasswordSent />}
                    /> */}
                    {/* <Route
                        path="/reset-password"
                        name="Reset Password"
                        element={<ResetPassword />}
                    /> */}
                    {/* <Route
                        exact
                        path="/reset-password-success"
                        name="Reset Password Success"
                        element={<ResetPasswordSuccess />}
                    /> */}
                    {/* <Route
                        exact
                        path="/reset-password-fail"
                        name="Reset Password Fail"
                        element={<PaymentReturnFail />}
                    /> */}
                    {/* <Route
                        exact
                        path="/verify-email"
                        name="Verify Email"
                        element={<VerifyEmail />}
                    /> */}
                    <Route path="dashboard" name="Dashboard" element={<DefaultLayout />}>
                        {routes?.map((item, index) => (
                            <Route
                                key={index}
                                path={item?.path}
                                name={item?.name}
                                element={<item.element />}
                            />
                        ))}
                    </Route>
                    <Route path="/" name="Home" element={<Landing />} />
                    <Route path="*" name="Not Found" element={<Page404 />} />
                </Routes>
            </Suspense>
        </HashRouter>
    )
}

export default App
