import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const CustomerRaffleSlide = createSlice({
    name: "customerRaffle",
    initialState: {
        remainingDraws: 0,
        rafflePrize: null,
        drawRaffleSuccess: false,
        drawRaffleFail: false,
        drawRaffleErrorDetail: null,
        coupons: []
    },
    reducers: {
        doGetRemainingDrawsSuccess: (state, action) => {
            state.remainingDraws = action.payload.data;
        },
        doGetRemainingDrawsFail: (state, action) => {
            state.remainingDraws = 0;
        },
        doDrawCampaignRaffleSuccess: (state, action) => {
            state.rafflePrize = action.payload.data;
            state.drawRaffleSuccess = true;
            state.drawRaffleFail = false;
            state.drawRaffleErrorDetail = null;
        },
        doDrawCampaignRaffleFail: (state, action) => {
            state.rafflePrize = null;
            state.drawRaffleSuccess = false;
            state.drawRaffleFail = true;
            state.drawRaffleErrorDetail = action.payload.data;
        },
        resetDrawRaffleState: (state, action) => {
            state.rafflePrize = null;
            state.drawRaffleSuccess = false;
            state.drawRaffleFail = false;
            state.drawRaffleErrorDetail = null;
        },
        doGetCampaignCouponsSuccess: (state, action) => {
            state.coupons = action.payload.data;
        },
        doGetCampaignCouponsFail: (state, action) => {
            state.coupons = [];
        },
    },
});

export const doGetCampaignRemainingDraws = (campaign_id) => async (dispatch) => {
    try {
        const url = `/api/customer/raffle/draw/remaining_draw/${campaign_id}`;
        const response = await axios.get(url);
        dispatch(doGetRemainingDrawsSuccess(response.data));
    } catch (error) {
        dispatch(doGetRemainingDrawsFail(error.response));
    }
};

export const doDrawCampaignRaffle = (campaign_id) => async (dispatch) => {
    try {
        const url = `/api/customer/raffle/draw/${campaign_id}`;
        const response = await axios.get(url);
        dispatch(doDrawCampaignRaffleSuccess(response.data));
    } catch (error) {
        dispatch(doDrawCampaignRaffleFail(error.response));
    }
};

export const doResetDrawRaffleState = () => async (dispatch) => {
    dispatch(resetDrawRaffleState())
};

export const doGetCampaignCoupons = (campaign_id) => async (dispatch) => {
    try {
        const url = `/api/customer/raffle/coupons/${campaign_id}`;
        const response = await axios.get(url);
        dispatch(doGetCampaignCouponsSuccess(response.data));
    } catch (error) {
        dispatch(doGetCampaignCouponsFail(error.response));
    }
};

export const {
    doGetRemainingDrawsSuccess,
    doGetRemainingDrawsFail,
    doDrawCampaignRaffleSuccess,
    doDrawCampaignRaffleFail,
    resetDrawRaffleState,
    doGetCampaignCouponsSuccess,
    doGetCampaignCouponsFail,
} = CustomerRaffleSlide.actions;

export const getRemainingDraws = (state) => state.customerRaffle?.remainingDraws;
export const getDrawRaffleSuccess = (state) => state.customerRaffle?.drawRaffleSuccess;
export const getDrawRaffleFail = (state) => state.customerRaffle?.drawRaffleFail;
export const getDrawRaffleErrorDetail = (state) => state.customerRaffle?.drawRaffleErrorDetail;
export const getRafflePrize = (state) => state.customerRaffle?.rafflePrize;
export const getCampaignCoupons = (state) => state.customerRaffle?.coupons;

export default CustomerRaffleSlide.reducer;
